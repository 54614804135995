* {
  text-align: center;
}
h1 {
  font-family: "dcdb-Bold";
  margin-top: 20px;
  margin-bottom: 20px;
}
h3 {
  font-family: "dcdb-Bold";
  margin-top: 20px;
}
.notice-image {
  height: 50vh;
  background-image: url("../images/notice-bg.jpg");
  background-size: cover;
  background-position: center;
}
.order {
  padding: 1%;
  background-color: rgba(255, 228, 196, 0.199);
  margin-bottom: 2%;
}
.howto {
  padding: 1%;
  background-color: rgba(255, 228, 196, 0.199);
  margin-bottom: 2%;
}
.notice-contents {
  font-size: 150%;
}
