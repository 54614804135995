@font-face {
  font-family: "dcdb-Bold";
  src: url("./fonts/Bold.ttf");
}
@font-face {
  font-family: "dcdb-Regular";
  src: url("./fonts/Regular.ttf");
}
.nav {
  background-color: rgba(255, 228, 196, 0.199);
}
.nav-home {
  font-family: "dcdb-Bold";
  padding: 10px;
  font-size: 200%;
}
.nav-list {
  font-family: "dcdb-Regular";
  font-size: 150%;
}
.main-image {
  height: 50vh;
  background-image: url("./images/bg.jpg");
  background-size: cover;
  background-position: center;
}
.nav-image {
  margin-top: 30px;
}
